import {Loader} from "@googlemaps/js-api-loader"

let loader = undefined

export function loadGMapApi(options) {
  if (loader === undefined) {
    loader = new Loader({
      apiKey: options.key,
      version: "weekly",
      libraries: options.libraries,
    })
  }

  return loader
}
