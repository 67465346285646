<template>
    <div v-html="serverHtml">
    </div>
</template>

<script setup>
import { inject } from 'vue'

const serverHtml = inject('serverHtml')
console.error("Error Loading Component")
</script>
