import {createApp, defineAsyncComponent} from 'vue'
import buildComponent from './components/build-component'
import MapElementMixin from './components/mapElementMixin'
import {loadGMapApi} from './load-google-maps'
import {Env} from "./utils/env";
import MountableMixin from './utils/mountableMixin'

const Map = defineAsyncComponent(() => import('./components/map'))
const Marker = defineAsyncComponent(() => import('./components/marker'))
const GMapKmlLayer = defineAsyncComponent(() => import('./components/kmlLayer'))
const GMapBounds = defineAsyncComponent(() => import('./components/bounds'))
const InfoWindow = defineAsyncComponent(() => import('./components/infoWindow'))
const GMapCluster = defineAsyncComponent(() => import('./components/cluster'))
const Polyline = defineAsyncComponent(() => import('./components/polyline'))
const Polygon = defineAsyncComponent(() => import('./components/polygon'))
const Circle = defineAsyncComponent(() => import('./components/circle'))
const Rectangle = defineAsyncComponent(() => import('./components/rectangle'))
const Autocomplete = defineAsyncComponent(() => import('./components/autocomplete'))
const Heatmap = defineAsyncComponent(() => import('./components/heatmap'))

export {
  loadGMapApi,
  Marker,
  Polyline,
  Polygon,
  Circle,
  GMapCluster,
  Rectangle,
  InfoWindow,
  Map,
  MapElementMixin,
  Heatmap,
  buildComponent,
  Autocomplete,
  MountableMixin,
}

export default function install(Vue, options) {
  options = {
    installComponents: true,
    autobindAllEvents: false,
    ...options,
  }

  const defaultResizeBus = createApp()

  const gmapApiLoader = makeGMapApiLoader(options)

  Vue.mixin({
    created() {
      this.$gmapDefaultResizeBus = defaultResizeBus
      this.$gmapOptions = options
      this.$gmapApiLoader = gmapApiLoader
    },
  })
  Vue.$gmapDefaultResizeBus = defaultResizeBus
  Vue.$gmapApiLoader = gmapApiLoader

  if (options.installComponents) {
    Vue.component('GMapMap', Map)
    Vue.component('GMapMarker', Marker)
    Vue.component('GMapKmlLayer', GMapKmlLayer)
    Vue.component('GMapBounds', GMapBounds)
    Vue.component('GMapInfoWindow', InfoWindow)
    Vue.component('GMapCluster', GMapCluster)
    Vue.component('GMapPolyline', Polyline)
    Vue.component('GMapPolygon', Polygon)
    Vue.component('GMapCircle', Circle)
    Vue.component('GMapRectangle', Rectangle)
    Vue.component('GMapAutocomplete', Autocomplete)
    Vue.component('GMapHeatmap', Heatmap)
  }
}

function makeGMapApiLoader(options) {
  if (Env.isServer()) {
    return {};
  }

  return loadGMapApi(options.load)
}
