import { createRouter, createWebHistory } from 'vue-router'
import { defineAsyncComponent } from 'vue';

const HomeView = defineAsyncComponent(() => import('@/views/Home/HomeView.vue'));
const FaqView = defineAsyncComponent(() => import('@/views/FAQView/FaqView.vue'));
const AboutUsView = defineAsyncComponent(() => import('@/views/AboutUs/AboutUsView.vue'));
const OurTeamView = defineAsyncComponent(() => import('@/views/OurTeam/OurTeamView.vue'));
const BlogDetails = defineAsyncComponent(() => import('@/views/BlogDetails/BlogDetailsView'));
const ApplicationView = defineAsyncComponent(() => import('@/views/Applications/ApplicationView'));
const ApplicationDetailsView = defineAsyncComponent(() => import('@/views/ApplicationDetails/ApplicationDetailsView'));
const LocationDetailsView = defineAsyncComponent(() => import('@/views/LocationDetails/LocationDetailsView.vue'));
const ResourcesView = defineAsyncComponent(() => import('@/views/Resources/ResoucesView'));
const ContactUsView = defineAsyncComponent(() => import('@/views/ContactUs/ContactUsView'));
const HubPageView = defineAsyncComponent(() => import('@/views/hubpage/HubPageView'));
const ProductsListOfCategoryView = defineAsyncComponent(() => import('@/views/ProductsListOfCategory/ProductsListOfCategoryView'));
const ProductCategoryDetailsView = defineAsyncComponent(() => import('@/views/ProductCategoryDetails/ProductCategoryDetailsView'));
const PressReleasesView = defineAsyncComponent(() => import('@/views/PressReleses/PressReleasesView'));
const SearchResultView = defineAsyncComponent(() => import('@/views/SearchResult/SearchResultView.vue'));
const CareersView = defineAsyncComponent(() => import('@/views/Careers/CareersView.vue'));

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: AboutUsView,
  },
  {
    path: '/about-us/our-team',
    name: 'our-team',
    component: OurTeamView
  },
  {
    path: '/blog-details',
    name: 'blog-details',
    component: BlogDetails,
  },
  {
    path: '/apps',
    name: 'apps',
    component: ApplicationView
  },
  {
    path: '/apps-details',
    name: 'apps-details',
    component: ApplicationDetailsView
  },
  {
    path: '/location-details',
    name: 'location-details',
    component: LocationDetailsView,
  },
  {
    path: '/faq',
    name: 'faq',
    component: FaqView,
  },
  {
    path: '/api/faq/search',
    name: 'faq-search',
    component: FaqView,
  },
  {
    path: '/resources',
    name: 'resources',
    component: ResourcesView,
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: ContactUsView,
  },
  {
    path: '/hub',
    name: 'hub',
    component: HubPageView,
  },
  {
    path: '/product-category',
    name: 'products-list-of-category',
    component: ProductsListOfCategoryView,
  },
  {
    path: '/pc-details',
    name: 'product-category-details',
    component: ProductCategoryDetailsView,
  },
  {
    path: '/pr',
    name: 'press-releases',
    component: PressReleasesView,
  },
  {
    path: '/search-results',
    name: 'search-result',
    component: SearchResultView,
  },
  {
    path: '/careers',
    name: 'careers',
    component: CareersView,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
